import React, { createContext, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NotificationContext = createContext();

export const useNotification = () => {
  return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
  const addNotification = (message) => {
    toast(message);
  };

  return (
    <NotificationContext.Provider value={{ addNotification }}>
      {children}
      <ToastContainer
        position="bottom-right"
        theme="dark"
        pauseOnHover
        newestOnTop={true}
        pauseOnFocusLoss
        autoClose={5000}
      />
    </NotificationContext.Provider>
  );
};
