import React from "react";
import { Outlet, Navigate } from "react-router-dom";

const RolesAuthentication = ({ allowedRoles }) => {
  const role = JSON.parse(localStorage?.getItem("currentRole"));
  const renderContent = () => {
    if (allowedRoles.includes(role?.value)) {
      return <Outlet />;
    }
    return <Navigate to="/" />;
  };
  return renderContent();
};

export default RolesAuthentication;
