import React from "react";
import Routes from "./Routes";
import { NotificationProvider } from "./components/notificationProvider/NotificationProvider";
import { SocketProvider } from "./components/socketContext/SocketContext";
import { AdminProfileProvider, LogoProvider, ProfileImageProvider } from "./components/provider/Provider";
import ErrorBoundary from "./utils/ErrorBoundary";

const App = () => {
  return (
    <ErrorBoundary>
      <NotificationProvider>
        <SocketProvider>
          <AdminProfileProvider>
            <LogoProvider>
              <ProfileImageProvider>
                <Routes />
              </ProfileImageProvider>
            </LogoProvider>
          </AdminProfileProvider>
        </SocketProvider>
      </NotificationProvider>
    </ErrorBoundary>
  );
};

export default App;
