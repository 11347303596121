import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import io from "socket.io-client";
import { useNotification } from "../notificationProvider/NotificationProvider";
import { getAdminId, getAgentId } from "../../utils/auth";

export const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const [socketData, setSocketData] = useState(null);
  const socket = useMemo(() => io(process.env.REACT_APP_API_GATEWAY), []);
  const { addNotification } = useNotification();

  useEffect(() => {
    if (getAdminId()) {
      socket.on("connect", () => {
        const uid = getAdminId();
        const dt = {
          uid: uid,
          usertype: "admin",
          connid: socket.id,
        };
        socket.emit("register", dt);
      });
    }
    

    if (getAgentId()) {
      socket.on("connect", () => {
        const uid = localStorage?.getItem("dc");
        const dt = {
          uid: uid,
          usertype: "agent",
          connid: socket.id,
        };
        socket.emit("register", dt);
      });
    }

    socket.on("recv", (d) => {});

    socket.on("notification", (data) => {
      setSocketData(data);
      addNotification(data.message);
      document.title = `Travelofy - New Notification`;
    });

    return () => {
      socket.disconnect();
    };
  }, [socket, addNotification]);

  return (
    <SocketContext.Provider value={{ socket, socketData }}>
      {children}
    </SocketContext.Provider>
  );
};
