import React from "react";
import Modal from "../../../reuseable/modal/Modal";
import { Dialog } from "@headlessui/react";
import { CheckIcon, QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";

const RoleModal = ({ open, setOpen, value, setSelected }) => {
  const navigate = useNavigate();
  return (
    <Modal open={open} setOpen={setOpen}>
      <div>
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
          <QuestionMarkCircleIcon
            className="h-12 w-12 text-green-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-base font-medium leading-6 text-gray-600"
          >
            Are you sure you want to change your role to{" "}
            <span className="font-semibold text-black text-base">
              {value.label}
            </span>
            ?
          </Dialog.Title>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
          onClick={() => {
            setSelected(value);
            localStorage.setItem("currentRole", JSON.stringify(value));
            setOpen(false);
            window.location.href = "/";
          }}
        >
          confirm
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
          onClick={() => setOpen(false)}
          data-autofocus
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default RoleModal;
