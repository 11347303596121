import { Fragment, useRef, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { Combobox } from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import RoleModal from "../../../layout/header/roleModal/RoleModal";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SimpleDropdown({ options, selected, onChange }) {
  return (
    <Listbox value={selected} onChange={onChange}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block truncate">
                {selected ? selected.label : "No data found!!!"}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options?.map((person) => (
                  <Listbox.Option
                    key={person.value}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-indigo-600 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={person}
                  >
                    {({ active }) => (
                      <>
                        <span
                          className={classNames(
                            selected.value === person.value
                              ? "font-semibold"
                              : "font-normal",
                            "block truncate capitalize"
                          )}
                        >
                          {person.label}
                        </span>

                        {selected.value === person.value ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
export function SimpleDropdownWoBorder({ options, selected, onChange, css }) {
  return (
    <Listbox value={selected} onChange={onChange}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button
              className={`relative cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 sm:text-sm sm:leading-1 w-72`}
            >
              <span className="block truncate">
                {selected ? selected.label : "No data found!!!"}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options?.map((person) => (
                  <Listbox.Option
                    key={person.value}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-indigo-600 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate capitalize"
                          )}
                        >
                          {person.label}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

const people = [
  {
    id: 1,
    label: "Leslie Alexander",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  // More users...
];

export const SearchDropDown = ({
  placeholder,
  options,
  selected,
  setSelected,
  name,
  className = "border-0",
}) => {
  const selectRef = useRef(null);
  const [query, setQuery] = useState("");
  const filteredPeople =
    query === ""
      ? options
      : options.filter((person) => {
          return person.label.toLowerCase().includes(query.toLowerCase());
        });
  const handleSelectClick = () => {
    // Focus and select the text in the select input when it is clicked
    selectRef.current.focus();
    selectRef.current.select();
  };

  return (
    <Combobox as="div" value={selected} onChange={setSelected}>
      <div className="relative">
        <Combobox.Button className={" w-full"}>
          <Combobox.Input
            placeholder={placeholder}
            ref={selectRef}
            onClick={handleSelectClick}
            className={`${className} w-full rounded-md  bg-white py-1.5 pl-3 pr-10 text-gray-900 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6 `}
            onChange={(event) => {
              setQuery(event.target.value);
            }}
            displayValue={(person) => person?.label}
          />
        </Combobox.Button>
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredPeople.length > 0 && (
          <Combobox.Options className="absolute z-40 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredPeople.map((person) => (
              <Combobox.Option
                key={person.value}
                value={person}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected && "font-semibold"
                      )}
                    >
                      {person.label}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};
export const SearchDropDownFormik = ({
  placeholder,
  options,
  selected,
  setSelected,
  name,
  className = "border-0",
}) => {
  const selectRef = useRef(null);
  const [query, setQuery] = useState("");
  const filteredPeople =
    query === ""
      ? options
      : options.filter((person) => {
          return person.label.toLowerCase().includes(query.toLowerCase());
        });
  const handleSelectClick = () => {
    // Focus and select the text in the select input when it is clicked
    selectRef.current.focus();
    selectRef.current.select();
  };

  return (
    <Combobox as="div" value={selected} onChange={setSelected}>
      <div className="relative">
        <Combobox.Button className={" w-full"}>
          <Combobox.Input
            placeholder={placeholder}
            ref={selectRef}
            onClick={handleSelectClick}
            className={`${className} w-full rounded-md  bg-white py-1.5 pl-3 pr-10 text-gray-900 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6 `}
            onChange={(event) => {
              setQuery(event.target.value);
            }}
            displayValue={(person) => person?.label}
          />
        </Combobox.Button>
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredPeople.length > 0 && (
          <Combobox.Options className="absolute z-40 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredPeople.map((person) => (
              <Combobox.Option
                key={person.value}
                value={person}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected && "font-semibold"
                      )}
                    >
                      {person.label}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export const MultipleSearchDropDown = ({
  placeholder,
  options,
  selected,
  setSelected,
  name,
  setFieldValue,
  index,
  className = "border-0",
}) => {
  const selectRef = useRef(null);
  const [query, setQuery] = useState("");
  const filteredPeople =
    query === ""
      ? options
      : options.filter((person) => {
          return person.label.toLowerCase().includes(query.toLowerCase());
        });
  const handleSelectClick = () => {
    // Focus and select the text in the select input when it is clicked
    selectRef.current.focus();
    selectRef.current.select();
  };
  return (
    <Combobox
      as="div"
      value={selected[index]}
      onChange={(val) => {
        const newArr = [...selected];
        if (val.ISO) {
          newArr[index] = {
            ...newArr[index],
            value: val.value,
            label: val.label,
            ISO: val.ISO,
          };
          setSelected(newArr);
        } else {
          newArr[index] = {
            ...newArr[index],
            value: val.value,
            label: val.label,
          };
          setSelected(newArr);
        }
        if (setFieldValue) {
          setFieldValue(name, val);
        }
      }}
    >
      <div className="relative">
        <Combobox.Button className={" w-full"}>
          <Combobox.Input
            placeholder={placeholder}
            ref={selectRef}
            onClick={handleSelectClick}
            className={`${className} w-full rounded-md  bg-white py-1.5 pl-3 pr-10 text-gray-900 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6 `}
            onChange={(event) => {
              setQuery(event.target.value);
            }}
            displayValue={(person) => person?.label}
          />
        </Combobox.Button>
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredPeople.length > 0 && (
          <Combobox.Options className="absolute z-40 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredPeople.map((person) => (
              <Combobox.Option
                key={person.value}
                value={person}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected && "font-semibold"
                      )}
                    >
                      {person.label}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export function AdminHeaderDropdown({ options, selected, setSelected }) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  return (
    <>
      <RoleModal
        open={open}
        setOpen={setOpen}
        value={value}
        setSelected={setSelected}
      />
      <Listbox
        value={selected}
        onChange={(val) => {
          if (val.value !== selected.value) {
            setOpen(true);
            setValue(val);
          }
        }}
      >
        {({ open }) => (
          <>
            <div className="relative">
              <label className="text-xs text-gray-500 ml-1">Role</label>
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-0 pl-1 pr-2 text-left text-gray-900  ring-0 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <span className="block truncate">{selected?.label}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-[150%] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options?.map((person) => (
                    <Listbox.Option
                      key={person?.value}
                      className={({ active }) =>
                        classNames(
                          active ? "bg-indigo-600 text-white" : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-9"
                        )
                      }
                      value={person}
                    >
                      {({ active }) => (
                        <>
                          <span
                            className={classNames(
                              selected.value === person.value
                                ? "font-semibold"
                                : "font-normal",
                              "block truncate capitalize"
                            )}
                          >
                            {person.label}
                          </span>
                          {selected.value === person.value ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-indigo-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </>
  );
}
