import React, { useState } from "react";
import { AdminHeader, AgentHeader } from "./header/Header";
import Sidebar from "./sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import { FooterAgent } from "./footer/Footer";
import { NotificationProvider } from "../components/notificationProvider/NotificationProvider";
import { SocketProvider } from "../components/socketContext/SocketContext";

const MainLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [bgSidebarOpen, setBgSidebarOpen] = useState(true);
  return (
  
        <div>
          {/* <Sidebar /> */}
          <Sidebar
            setSidebarOpen={setSidebarOpen}
            sidebarOpen={sidebarOpen}
            bgSidebarOpen={bgSidebarOpen}
          />
          <div
            className={bgSidebarOpen ? "lg:pl-64 duration-300" : "duration-300"}
          >
            <AdminHeader
              setSidebarOpen={setSidebarOpen}
              setBgSidebarOpen={setBgSidebarOpen}
              bgSidebarOpen={bgSidebarOpen}
            />
            <main>
              <div>
                <Outlet />
              </div>
            </main>
          </div>
        </div>
    
  );
};

export default MainLayout;

export const SecondaryLayout = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <AgentHeader />
      <main className="flex-grow">
        <div>
          <Outlet />
        </div>
      </main>
      <FooterAgent />
    </div>
  );
};
