import axios from "axios";
import React, { useState } from "react";
import moment from "moment";

export const get = (url) => {
  const fetchData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_GATEWAY}${url}`);
      return res;
    } catch (error) {
      return error;
    }
  };
  return fetchData();
};

export const post = async (url, data) => {
  try {
    let r = await axios.post(`${process.env.REACT_APP_API_GATEWAY}${url}`, {
      ...data,
    });
    return r;
  } catch (error) {
    return error;
  }
};

export const checkLength = (state, min, max) => {
  if (state.length === 0) {
    return {
      status: 404,
      msg: `Required feild.`,
    };
  } else if (state.length < min || state.length > max) {
    return {
      status: 404,
      msg: `Must contain min-${min} and max-${max}.`,
    };
  } else {
    return {
      status: 200,
      msg: ``,
    };
  }
};

const NumberToWordsConverter = () => {
  const [number, setNumber] = useState("");
  const [words, setWords] = useState("");

  const convertToWords = () => {
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    const digits = number.toString().split("").map(Number);

    let result = "";

    if (digits.length === 3) {
      result += units[digits[0]] + " Hundred ";
      digits.shift();
    }

    if (digits.length === 2) {
      if (digits[0] === 1) {
        result += teens[digits[1]] + " ";
      } else {
        result += tens[digits[0]] + " " + units[digits[1]] + " ";
      }
    } else if (digits.length === 1) {
      result += units[digits[0]] + " ";
    }

    setWords(result.trim());
  };

  return (
    <div>
      <label>
        Enter a number:
        <input
          type="number"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
        />
      </label>
      <button onClick={convertToWords}>Convert</button>
      {words && (
        <div>
          <p>{`${number} in words: ${words}`}</p>
        </div>
      )}
    </div>
  );
};

export default NumberToWordsConverter;

// export function calculateAgeFromDate(date) {
//   var birthDate = new Date(date);
//   var currentDate = new Date();
//   var diff = currentDate.getTime() - birthDate.getTime();
//   var ageDate = new Date(diff);
//   return Math.abs(ageDate.getUTCFullYear() - 1970);
// }

export function calculateAgeFromDate(date) {
  const birthDate = moment(date);
  const currentDate = moment();
  const age = currentDate.diff(birthDate, "years");
  return Math.abs(age);
}
// Example usage:
export function formatDate(dateString) {
  // Create a new Date object with the given date string
  var date = new Date(dateString);

  // Extract year, month, and day from the Date object
  var year = date.getFullYear();
  var month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed, so we add 1
  var day = date.getDate().toString().padStart(2, "0");

  // Format the date into "yyyy mm dd" format
  var formattedDate = year + "-" + month + "-" + day;

  // Return the formatted date
  return formattedDate;
}

///////////////////////////////////////////////////// 23 Jan 2024" format

export const formatWordDate = (dateString) => {
  // Create a Date object from the input string
  const date = new Date(dateString);

  // Months array
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract day, month, and year
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  // Construct the formatted date string
  const formattedDate = `${day} ${month} ${year}`;

  return formattedDate;
};

export function timeAgo(inputTime) {
  const now = new Date();
  const past = new Date(inputTime);
  const diffInMs = now - past;

  const msInMinute = 60 * 1000;
  const msInHour = 60 * msInMinute;
  const msInDay = 24 * msInHour;

  const daysAgo = Math.floor(diffInMs / msInDay);
  const hoursAgo = Math.floor((diffInMs % msInDay) / msInHour);
  const minutesAgo = Math.floor((diffInMs % msInHour) / msInMinute);

  if (daysAgo > 0) {
    return `${daysAgo} day${daysAgo > 1 ? "s" : ""} ago`;
  } else if (hoursAgo > 0) {
    return `${hoursAgo} hr${hoursAgo > 1 ? "s" : ""} ago`;
  } else {
    return `${minutesAgo} min${minutesAgo > 1 ? "s" : ""} ago`;
  }
}

export const ShowError = () => {
  return (
    <div className="text-red-600 font-bold text-xl">Something went wrong</div>
  );
};

export function formatDateWordAndTime(dateStr) {
  const date = new Date(dateStr);

  // Options for formatting the date
  const options = {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  // Format the date
  const formattedDate = date.toLocaleString("en-US", options);

  // Custom format to get the desired output
  const parts = formattedDate?.split(" ");

  const datePart = `${parts?.[1]?.replace(",", "")} ${
    parts?.[0]
  } ${parts?.[2]?.replace(",", "")}`;

  const timePart = `${parts?.[3]} ${parts?.[4]}`;
  return `${datePart} : ${timePart}`;
}

//////////////////////////////////////// format 24/06/2024

export function formatDateAsPerDateRange(isoString) {
  // Create a new Date object from the ISO string
  const date = new Date(isoString);

  // Extract the day, month, and year from the Date object
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
  const year = date.getFullYear();

  // Format the date as DD/MM/YYYY
  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
}
const TO_RADIANS = Math.PI / 180;

export const setCanvasPreview = (
  image, // HTMLImageElement
  canvas, // HTMLCanvasElement
  crop, // PixelCrop
  rotation = 0, // Rotation angle in degrees
  scale = 1 // Scale factor
) => {
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  const scaleX = image?.naturalWidth / image?.width;
  const scaleY = image?.naturalHeight / image?.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window?.devicePixelRatio;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotation * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
};



//        file input
export const downloadImageHandler = (imageSrc, name) => {
  const link = document.createElement("a");
  link.href = imageSrc;
  link.download = name || "downloaded_image";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
//    backend image
export const handleDownload = async (imageUrl, imageName) => {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = imageName || "downloaded_image";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
};







///////////////////////// GET IMAGE SIZE

export const getImageSize = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    const sizeInBytes = blob.size;
    const sizeInKB = sizeInBytes / 1024;

    let formattedSize = '';

    if (sizeInKB < 1024) {
      formattedSize = `${sizeInKB.toFixed(2)} KB`;
    } else {
      const sizeInMB = sizeInKB / 1024;
      formattedSize = `${sizeInMB.toFixed(2)} MB`;
    }

    return formattedSize;
  } catch (error) {
    console.error('Error fetching image:', error);
    return 'Error fetching size';
  }
};
