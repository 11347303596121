import { Fragment } from "react";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import {
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  HomeIcon,
  UserIcon,
  XMarkIcon,
  BanknotesIcon,
} from "@heroicons/react/24/outline";
import { IoIosGitBranch } from "react-icons/io";
import { PiSealCheckLight } from "react-icons/pi";
import { HiOutlineBriefcase } from "react-icons/hi";
import { HiOutlineUsers } from "react-icons/hi2";
import { NavLink } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { TbLocationQuestion } from "react-icons/tb";
import { PiSealCheckBold } from "react-icons/pi";

const navigation = [
  {
    name: "Dashboard",
    href: "/",
    icon: HomeIcon,
    current: true,
    allowedRoles: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    name: "User",
    icon: UserIcon,
    allowedRoles: [1, 2],
    children: [
      {
        name: "Create User",
        href: "/user/add",
        allowedRoles: [1, 2],
      },
      {
        name: "User List",
        href: "/user/list",
        allowedRoles: [1, 2],
      },
    ],
  },
  {
    name: "Agency",
    href: "/agency",
    icon: HiOutlineUsers,
    allowedRoles: [1, 2, 3, 7, 8, 9],
    current: true,
  },

  // {
  //   name: "Plans",
  //   icon: BanknotesIcon,
  //   allowedRoles: [1],
  //   children: [
  //     {
  //       name: "Pricing Plans",
  //       href: "/pricing-plan",
  //       allowedRoles: [1],
  //     },
  //     {
  //       name: "Assign Plans",
  //       href: "/assign-plan",
  //       allowedRoles: [1],
  //     },
  //   ],
  // },
  // {
  //   name: "Branch",
  //   icon: IoIosGitBranch,
  //   children: [
  //     {
  //       name: "Create Branch",
  //       href: "/branch/add",
  //     },
  //     {
  //       name: "Branch List",
  //       href: "/branch/list",
  //     },
  //   ],
  // },
  // {
  //   name: "Master",
  //   icon: IoIosGitBranch,
  //   allowedRoles: [1],
  //   children: [
  //     {
  //       name: "Currency",
  //       href: "/master/currency",
  //       allowedRoles: [1],
  //     },
  //     // {
  //     //   name: "Branch List",
  //     //   href: "/branch/list",
  //     // },
  //   ],
  // },
  {
    name: "Product",
    icon: DocumentDuplicateIcon,
    allowedRoles: [1],
    children: [
      {
        name: "Create Product",
        href: "/product/add",
        allowedRoles: [1],
      },
      {
        name: "Product Details",
        href: "/product/details",
        allowedRoles: [1],
      },
      {
        name: "Product List",
        href: "/product/list",
        allowedRoles: [1],
      },
    ],
  },
  // {
  //   name: "Vendor",
  //   icon: ChartPieIcon,
  //   allowedRoles: [1],
  //   children: [
  //     // {
  //     //   name: "Create Vendor",
  //     //   href: "/vendor/add",
  //     // },
  //     {
  //       name: "Vendor List",
  //       href: "/vendor/list",
  //       allowedRoles: [1],
  //     },
  //     // {
  //     //   name: "Vendor Pricing",
  //     //   href: "/vendor/pricing",
  //     // },
  //   ],
  // },
  {
    name: "Career",
    icon: HiOutlineBriefcase,
    allowedRoles: [1, 2],
    children: [
      {
        name: "Create Career",
        href: "/career/add",
        allowedRoles: [1, 2],
      },
      {
        name: "Career List",
        href: "/career/list",
        allowedRoles: [1, 2],
      },
      {
        name: "Career Enquiry",
        href: "/career/enquiry",
        allowedRoles: [1, 2],
      },
    ],
  },
  {
    name: "Visa Orders",
    href: "/orders-list",
    icon: HiOutlineBriefcase,
    allowedRoles: [1, 2, 3, 4, 5, 6],
    current: true,
  },
  {
    name: "General Enquiry",
    href: "/general-enquiry",
    icon: TbLocationQuestion,
    allowedRoles: [1, 2, 3, 7],
    current: true,
  },
  {
    name: "Visa Enquiry",
    href: "/visa-enquiry",
    allowedRoles: [1, 2, 3, 7],
    icon: PiSealCheckBold,
    current: true,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Sidebar({
  sidebarOpen,
  setSidebarOpen,
  bgSidebarOpen,
}) {
  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-end">
                      <img
                        className="h-10 w-auto"
                        src="/assets/AVS.png"
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                {item.allowedRoles.includes(
                                  JSON.parse(
                                    localStorage?.getItem("currentRole")
                                  ).value
                                ) ? (
                                  item.children === undefined ? (
                                    <NavLink
                                      to={item.href}
                                      className={({ isActive, isPending }) =>
                                        isPending
                                          ? " text-gray-700"
                                          : isActive
                                          ? " text-indigo-600 bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                          : "text-gray-700 group hover:text-indigo-600 hover:bg-gray-50 flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold"
                                      }
                                    >
                                      <item.icon
                                        className={classNames(
                                          " h-6 w-6 shrink-0"
                                        )}
                                        aria-hidden="true"
                                      />
                                      {item.name}
                                    </NavLink>
                                  ) : (
                                    <Disclosure>
                                      {({ open }) => (
                                        <>
                                          <Disclosure.Button
                                            className={({
                                              isActive,
                                              isPending,
                                            }) =>
                                              isPending
                                                ? " text-gray-700 w-full  "
                                                : isActive
                                                ? " text-indigo-600 bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold  w-full  "
                                                : "text-gray-700 group hover:text-indigo-600  hover:bg-gray-50 flex justify-between  items-center gap-x-3 rounded-md  w-full  py-2 pl-2 pr-3 text-sm leading-6 font-semibold"
                                            }
                                          >
                                            <span className="flex ">
                                              <item.icon
                                                className={classNames(
                                                  " h-6 w-6 mr-3 shrink-0 text-gray-500"
                                                )}
                                                aria-hidden="true"
                                              />
                                              <span>{item.name}</span>
                                            </span>

                                            <ChevronDownIcon
                                              className={`${
                                                open
                                                  ? "rotate-180 transform"
                                                  : ""
                                              } h-5 w-5 text-indigo-500 self-start`}
                                            />
                                          </Disclosure.Button>
                                          {item?.children?.map((child) => {
                                            return (
                                              <Disclosure.Panel
                                                key={child.name}
                                              >
                                                <NavLink
                                                  onClick={() =>
                                                    setSidebarOpen(false)
                                                  }
                                                  className={({
                                                    isActive,
                                                    isPending,
                                                  }) =>
                                                    isPending
                                                      ? " text-gray-700   "
                                                      : isActive
                                                      ? " text-indigo-600 bg-gray-50 group flex gap-x-3 text-sm pl-11 rounded-md p-2 leading-6 font-semibold"
                                                      : "text-gray-500 group hover:text-indigo-600 hover:bg-gray-50 flex gap-x-3 rounded-md py-2 pl-11 pr-3 text-sm leading-6 font-semibold"
                                                  }
                                                  to={child.href}
                                                >
                                                  {child.name}
                                                </NavLink>
                                              </Disclosure.Panel>
                                            );
                                          })}
                                        </>
                                      )}
                                    </Disclosure>
                                  )
                                ) : null}
                              </li>
                            ))}
                          </ul>
                        </li>

                        <li className="mt-auto">
                          <a
                            href="#"
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                          >
                            <Cog6ToothIcon
                              className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                              aria-hidden="true"
                            />
                            Settings
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <Transition
          as="div"
          show={bgSidebarOpen}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          className={`hidden ${
            bgSidebarOpen
              ? "lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-64 lg:flex-col"
              : ""
          } `}
        >
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
            <div className="flex h-16 shrink-0 items-end">
              <img
                className="h-10 w-auto"
                src="/assets/AVS.png"
                alt="Your Company"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        {item.allowedRoles.includes(
                          JSON.parse(localStorage?.getItem("currentRole")).value
                        ) ? (
                          item.children === undefined ? (
                            <NavLink
                              to={item.href}
                              className={({ isActive, isPending }) =>
                                isPending
                                  ? " text-gray-700   "
                                  : isActive
                                  ? " text-indigo-600 bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  : "text-gray-500 group hover:text-indigo-600 hover:bg-gray-50 flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold"
                              }
                            >
                              <item.icon
                                className={classNames(" h-6 w-6 shrink-0")}
                                aria-hidden="true"
                              />
                              {item.name}
                            </NavLink>
                          ) : (
                            <Disclosure>
                              {({ open }) => (
                                <>
                                  <Disclosure.Button
                                    className={({ isActive, isPending }) =>
                                      isPending
                                        ? " text-gray-500 w-full  "
                                        : isActive
                                        ? " text-indigo-600 bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold  w-full  "
                                        : "text-gray-500 group hover:text-indigo-600  hover:bg-gray-50 flex justify-between  items-center gap-x-3 rounded-md  w-full  py-2 pl-2 pr-3 text-sm leading-6 font-semibold"
                                    }
                                  >
                                    <span className="flex ">
                                      <item.icon
                                        className={classNames(
                                          " h-6 w-6 mr-3 shrink-0"
                                        )}
                                        aria-hidden="true"
                                      />
                                      <span>{item.name}</span>
                                    </span>

                                    <ChevronDownIcon
                                      className={`${
                                        open ? "rotate-180 transform" : ""
                                      } h-5 w-5 text-indigo-500 self-start`}
                                    />
                                  </Disclosure.Button>
                                  {item?.children?.map((child) => {
                                    return (
                                      <Disclosure.Panel key={child.name}>
                                        <NavLink
                                          className={({
                                            isActive,
                                            isPending,
                                          }) =>
                                            isPending
                                              ? " text-gray-500   "
                                              : isActive
                                              ? " text-indigo-600 bg-gray-50 group flex gap-x-3 text-sm pl-11 rounded-md p-2 leading-6 font-semibold"
                                              : "text-gray-500 group hover:text-indigo-600 hover:bg-gray-50 flex gap-x-3 rounded-md py-2 pl-11 pr-3 text-sm leading-6 font-semibold"
                                          }
                                          to={child.href}
                                        >
                                          {child.name}
                                        </NavLink>
                                      </Disclosure.Panel>
                                    );
                                  })}
                                </>
                              )}
                            </Disclosure>
                          )
                        ) : null}
                      </li>
                    ))}
                  </ul>
                </li>

                <li className="mt-auto">
                  <a
                    href="#"
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                  >
                    <Cog6ToothIcon
                      className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                      aria-hidden="true"
                    />
                    Settings
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </Transition>
      </div>
    </>
  );
}
