import React, { createContext, useState } from 'react';

export const UserContext = createContext();

export const ProfileImageProvider = ({ children }) => {
  const [userImage, setUserImage] = useState(localStorage.getItem('userProfile') || '');

  return (
    <UserContext.Provider value={{ userImage, setUserImage }}>
      {children}
    </UserContext.Provider>
  );
};


export const LogoContext = createContext();

export const LogoProvider = ({ children }) => {
  const [logo, setLogo] = useState(localStorage.getItem('logoImage') || '');

  return (
    <LogoContext.Provider value={{ logo, setLogo }}>
      {children}
    </LogoContext.Provider>
  );
};


export const AdminProfileContext = createContext();

export const AdminProfileProvider = ({ children }) => {
  const [adminImage, setAdminImage] = useState(localStorage.getItem('profilePic') || '');

  return (
    <AdminProfileContext.Provider value={{ adminImage, setAdminImage }}>
      {children}
    </AdminProfileContext.Provider>
  );
};